import React from "react";
import {
  Area,
  Bar,
  BarChart,
  Cell,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useSelector } from "react-redux";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";

const OverstockWaterFallGraph = (props: any) => {
  const { waterfallData, type , kkey ,graphTab2 ,VendorData } = props;

  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );
  console.log(waterfallData)
  const processedWaterfallData = waterfallData?.map((data: {
    onhand: number | null;
    InvAtEndOfLT1: number | null;
    InvAtEndOfLT2: number | null;
  }) => ({
    ...data,
    Onhand: data.onhand !== null ? data.onhand : (data.InvAtEndOfLT1 !== null ? data.InvAtEndOfLT1 : data.InvAtEndOfLT2)
  }));


  return (
    <div className="w-full">
     

     {
      kkey == 1 ?
      <span className="text-white font-medium text-0.95vw ">
            {"Inventory Trend vs Target Inventory (Qty)"}
          </span> : <></>
    }
             

      <ResponsiveContainer
        width={"100%"}
        height={windowSize?.width / 6.5}
        className={`border-[1px] border-mainGray`}
      >
        <ComposedChart
          data={graphTab2 == 1 ? processedWaterfallData : VendorData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 10,
          }}
        >
          {graphTab2 === 1 && ( <XAxis interval={0} fontSize={10} stroke="#B5B5B5" dataKey="date" />)}
          {graphTab2 === 2 && (<XAxis interval={0} fontSize={10} angle={-30} tickMargin={12} stroke="#B5B5B5" dataKey="vendor_code" />)}
        {graphTab2 === 1 && (  <YAxis
            fontSize={10}
            stroke="#B5B5B5"
          
            tickFormatter={(value: any) => value && ` ${formatAmount(value)}`}
          />)}

          {graphTab2 === 2 && ( 
             <YAxis
             fontSize={10}
             stroke="#B5B5B5"
            dataKey="Rcmd_Qty$"
             tickFormatter={(value: any) =>  value && ` ${formatAmount(value)}` }
           />
          )}
          <Tooltip
            cursor={{ fill: "transparent" }}
            content={($) => <CustomTooltip data={$} type={type}  graphTab2 ={graphTab2}/>}
          />
  
         {graphTab2 === 1 && ( <Legend  content={($) => <CustomLegend  graphTab2={graphTab2} payload={$}/>}  iconType="square" verticalAlign="top" wrapperStyle={{ fontSize: "15px" }} />)}
         {graphTab2 === 2 && ( <Legend iconType="square" verticalAlign="top" wrapperStyle={{ fontSize: "15px" }} /> ) }
          {graphTab2 == 1 && ( <Line
            type="monotone"
             dataKey="Onhand"
            // dataKey={(data) => data?.onhand !== null ? data?.onhand : data?.InvAtEndOfLT1 !== null ? data?.InvAtEndOfLT1 : data?.InvAtEndOfLT2}
            fill="#82ca9d"
            stroke="#82ca9d"
            strokeWidth={3}
          />)}
      
        {graphTab2 == 1 && (<Area dataKey="adjusted" fill="#c9cac6" stroke="#c9cac6" strokeWidth="0.2" fillOpacity="0.2" />)}
          {/* <Line type="monotone" dot={{ strokeWidth: 0, r: 0 }} dataKey="effectivess" fill="#8884d8" stroke="#8884d8" /> */}
          {graphTab2 == 1 && ( <Area
            stackId="1"
            dataKey="effectivess"
            fill="#5b9dbc"
            stroke="#5b9dbc"
            fillOpacity="0.2"
            strokeWidth="0.2" 
          />)}
            {graphTab2 === 2 && (
                <Bar
                  name="Rcmd Qty ($)"
                  dataKey="Rcmd_Qty$"
                  stroke="#7AB2D3"
                  fill="#7AB2D3"
                  barSize={30}
                  strokeWidth={3}
                  // dot={{ strokeWidth: 1, r: 3 }}
                />
              )}
        </ComposedChart>

      </ResponsiveContainer>
    </div>
  );
};

const renderCustomizedLabel = (props: any) => {
  const { x, y, width, value, height } = props;
  return (
    <>
      {value !== 0 && (
        <text
          x={x + width / 2}
          y={y - (value > 0 ? 10 : -(height - 10))}
          fill="#fff"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {formatAmount(
            Number(parseFloat(value < 0 ? value * -1 : value).toFixed(2))
          )}
        </text>
      )}
    </>
  );
};

const CustomLegend = (props :any) => {
  const {  payload , graphTab2 } = props.payload;
  console.log(payload);
  
  return (
    <ul className="custom-legend flex justify-center">
      {payload.map((entry :any, index :any) => (
        <li key={`item-${index}`} style={{ color: entry.value === "effectivess" ? '#6a8290' : entry.value === "adjusted" ? '#666666': "#82ca9d"  , marginLeft :5 }}>
          <span style={{ backgroundColor: entry.value === "effectivess" ? '#6a8290' : entry.value === "adjusted" ? '#666666': "#82ca9d" , width: 12, height: 12, display: 'inline-block', marginRight: 5  }} />
         {entry.value === "effectivess" ? 'Eff. SS' : entry.value === "adjusted" ? 'Adj. ORQ': "Onhand Qty" }
        </li>
      ))}
    </ul>
  );
}

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;
  if (active && payload && payload.length) {
    console.log(payload);
    
    return (
      <>
        <div className="bg-[#353535] p-3">
           {props.graphTab2 === 1 && ( <>
            {/* <p className="text-white text-0.95vw">
              {payload[0]?.payload?.name}
            </p> */}
            {payload?.map(($: any) => {
              return (
                <>
                  {$?.name === "Onhand" && (
                    <p className="text-white text-0.7vw">
                      {$?.payload?.netinv ? "Onhand Qty" :$?.payload?.InvAtEndOfLT1 ? "Inv At End Of LT1" :$?.payload?.InvAtEndOfLT2 ? "Inv At End Of LT2" : "Onhand Qty"} :{" "}
                      {formatAmount($?.value)}
                    </p>
                  )}
                  {$?.name === "adjusted" && (
                    <p className="text-white text-0.7vw">
                      Eff. SS + Adj. ORQ : {formatAmount($?.value)}
                    </p>
                  )}
                  {$?.name === "effectivess" && (
                    <p className="text-white text-0.7vw">
                      Eff. SS : {formatAmount($?.value)}
                    </p>
                  )}
                
                </>
              );
            })}
          </>)}
{props.graphTab2 === 2 && (<>
 
    <>
      <p className="text-white text-0.7vw">
                      Rcmd Qty ($) : {formatAmount(payload[0]?.payload?.Rcmd_Qty$)}
                    </p>
    </>
</>)}
        </div>
      </>
    );
  }

  return null;
};

export default OverstockWaterFallGraph;
