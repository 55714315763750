import React, { useEffect, useState } from "react";
import {
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  ComposedChart,
} from "recharts";
import { useSelector } from "react-redux";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";
import { DUMMY } from "../../constants/dataConstant";
import { Button, ButtonGroup, ButtonToolbar } from "rsuite";
import OverstockWaterFallGraph from "./overstockWaterFallGraph";
import MuiTooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const POInventoryLineGraph = (props: any) => {
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );

  const { lineGraphData, graphType, waterfallData ,selectedRow ,selectedGBPFilter ,VendorData } = props;
  const [graphTab, setGraphTab] = useState(graphType ? graphType : 1);
  const [graphTab2, setGraphTab2] = useState(1);



  return (
    <>
      <div className="w-full">
        <div className={`flex w-full justify-between ${graphType ? "" : ""}`}>
          <div>
            <span className="text-white font-medium text-0.95vw ">
              {graphTab === 1
               ? selectedRow?.length > 0 ? `On Order vs  Demand (Qty) - ${selectedRow[0]?.plant}` : selectedGBPFilter?.plant?.length > 0 ? `On Order vs  Demand (Qty) - ${selectedGBPFilter?.plant[0]} Plant` : selectedGBPFilter?.clustername?.length > 0 ? `On Order vs  Demand (Qty) - ${selectedGBPFilter?.clustername[0]} Cluster` : "On Order vs  Demand (Qty)"

                : graphTab === 2
                ? "Inventory Details for Other Active Locations (Within the Cluster)"
                : graphTab === 3
                ?  selectedRow?.length > 0 ? `LT Trend - ${selectedRow[0]?.plant}` : selectedGBPFilter?.plant?.length > 0 ? `LT Trend - ${selectedGBPFilter?.plant[0]} Plant` : selectedGBPFilter?.clustername?.length > 0 ? `LT Trend - ${selectedGBPFilter?.clustername[0]} Cluster` : "LT Trend"
                : graphTab2 === 1 ? selectedRow?.length > 0 ? `OnHand vs Target Inventory (Qty) Trend - ${selectedRow[0]?.plant}` : selectedGBPFilter?.plant?.length > 0 ? `OnHand vs Target Inventory (Qty) Trend - ${selectedGBPFilter?.plant[0]} Plant` :  selectedGBPFilter?.clustername?.length > 0 ? `OnHand vs Target Inventory (Qty) Trend -  ${selectedGBPFilter?.clustername[0]} Cluster ` : "OnHand vs Target Inventory (Qty) Trend"  :
                  'Vendor Chart'}
            </span>
            {graphType === 4 && graphTab2 == 1 && (
              <MuiTooltip
                placement="top"
                className="font-bold"
                title={"Note: Data based on Inv. Sum"}
                arrow
              >
                <InfoOutlinedIcon
                  className="mb-2 ml-2"
                  sx={{ color: "white" }}
                ></InfoOutlinedIcon>
              </MuiTooltip>
            )}
          </div>
          {graphType !==  4 && (
            <ButtonToolbar className="pb-1 ">
              <ButtonGroup>
                <Button
                  onClick={() => {
                    setGraphTab(1);
                  }}
                  size="xs"
                  appearance="ghost"
                  className={`${
                    graphTab === 1
                      ? "bg-green-500 text-white"
                      : "bg-white text-black"
                  } border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
                >
                  On Order vs Demand
                </Button>
                {/* <Button
                onClick={() => setGraphTab(2)}
                appearance="ghost"
                size="xs"
                className={`${
                  graphTab === 2
                    ? "bg-green-500 text-white"
                    : "bg-white text-black"
                } border-black border-l-2  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
              >
                Inventory Trend
              </Button> */}
                <Button
                  onClick={() => setGraphTab(3)}
                  appearance="ghost"
                  size="xs"
                  className={`${
                    graphTab === 3
                      ? "bg-green-500 text-white"
                      : "bg-white text-black"
                  } border-black border-l-2  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
                >
                  LT Trend
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          )}
          {graphType === 4 && (
              <ButtonToolbar className="pb-1 ">
              <ButtonGroup>
                <Button
                  onClick={() => {
                    setGraphTab2(1);
                  }}
                  size="xs"
                  appearance="ghost"
                  className={`${
                    graphTab2 === 1
                      ? "bg-green-500 text-white"
                      : "bg-white text-black"
                  } border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
                >
                  OnHand vs Target
                </Button>
                {/* <Button
                onClick={() => setGraphTab(2)}
                appearance="ghost"
                size="xs"
                className={`${
                  graphTab === 2
                    ? "bg-green-500 text-white"
                    : "bg-white text-black"
                } border-black border-l-2  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
              >
                Inventory Trend
              </Button> */}
                <Button
                  onClick={() => setGraphTab2(2)}
                  appearance="ghost"
                  size="xs"
                  className={`${
                    graphTab2 === 2
                      ? "bg-green-500 text-white"
                      : "bg-white text-black"
                  } border-black border-l-2  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
                >
                  Vendor Chart
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          )}
        </div>
        {graphType !== 4 && (
          <ResponsiveContainer
            width={"100%"}
            height={windowSize?.width / 6.5}
            className={`border-[1px] border-mainGray`}
          >
            <ComposedChart
              margin={{
                top: 0,
                right: 50,
                left: 10,
                bottom: 0,
              }}
              layout="horizontal"
              data={
                graphTab === 1
                  ? lineGraphData?.demand
                  : graphTab === 2
                  ? lineGraphData?.inventory
                  : lineGraphData?.repData
              }
            >
              <XAxis
                fontSize={10}
                stroke="#B5B5B5"
                dataKey="date"
                type="category"
              />

              {graphTab === 2 && (
                <YAxis
                  fontSize={10}
                  stroke="#B5B5B5"
                  dataKey="onhand"
                  tickFormatter={(value: any) =>
                    value && `${formatAmount(value)}`
                  }
                />
              )}
              <YAxis
                stroke="#B5B5B5"
                fontSize={10}
                tickFormatter={(value: any) =>
                  value && `${formatAmount(value)}`
                }
              />

              <Tooltip content={($) => <CustomTooltip data={$} />} />
              <Legend verticalAlign="top" wrapperStyle={{ fontSize: "15px" }} />
              <ReferenceLine x="Page C" stroke="green" />

              {graphTab === 1 && (
                <Bar
                  name="Actual Demand"
                  dataKey="demand"
                  stroke="#9FA1A3"
                  fill="#9FA1A3"
                  barSize={30}
                  strokeWidth={3}
                  // dot={{ strokeWidth: 1, r: 3 }}
                />
              )}
              {graphTab === 1 && (
                <Bar
                  name="Forecast"
                  dataKey="forecast"
                  stroke="#62B5E5"
                  fill="#62B5E5"
                  strokeWidth={3}
                  barSize={30}
                  // dot={{ strokeWidth: 1, r: 3 }}
                />
              )}
              {/* {graphTab === 1 && (
                <Line
                  name="On Order"
                  dataKey="purchases"
                  stroke="#0097A9"
                  fill="#0097A9"
                  strokeWidth={3}
                  dot={{ strokeWidth: 1, r: 3 }}
                />
              )} */}
              {/* {graphTab===1&&<Bar
              dataKey="connect"
              stroke="#ffffff"
              // fill="#ffffff"
              strokeWidth={1}
              legendType='none'
              strokeDasharray="5 5"
            />} */}

              {graphTab === 2 && (
                <Line
                  name="On Hand (Qty)"
                  dataKey="onhand"
                  stroke="#0097a9"
                  fill="#0097a9"
                  strokeWidth={3}
                  dot={{ strokeWidth: 1, r: 3 }}
                />
              )}
              {graphTab === 3 && (
                <Line
                  name="Rep LT (Weeks)"
                  dataKey="replt"
                  stroke="#fff"
                  fill="#fff"
                  strokeWidth={3}
                  dot={{ strokeWidth: 1, r: 3 }}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        )}
        {graphType === 4 && (
          <OverstockWaterFallGraph
            waterfallData={waterfallData}
            graphTab2={graphTab2}
            VendorData={VendorData}
            kkey={2}
            type={1}
          />
        )}
      </div>
    </>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;
  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          <>
            <p className="text-white text-0.95vw">
              {payload[0]?.payload?.forecastDate}
            </p>
            {payload?.map(($: any) => {
              return (
                <>
                  <p key={$?.name} className="text-white text-0.7vw">
                    {$?.name} : {formatAmount($?.value)}
                  </p>
                </>
              );
            })}
          </>
        </div>
      </>
    );
  }

  return null;
};

export default POInventoryLineGraph;
