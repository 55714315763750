import React, { useEffect, useState } from "react";
import { Loader } from "rsuite";
import OverstockInventoryBarGraph from "../../../components/inventory/overstockInventoryBarGraph";
import OverstockInventoryTable from "../../../components/inventory/overstockInventoryTable";
import OverstockInventoryLineGraph from "../../../components/inventory/overstockInventoryLineGraph";
import {
  getDemandInventoryLineGraphData,
  getHighLowDOSTrendChart,
  getOverstockChartData,
  getOverstockPlantChartData,
  updateInventoryComments,
} from "../../../queries/apiQueries";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import StockoutInventoryLineGraph from "../../../components/inventory/stockoutInventoryLineGraph";
import HighLowDOSTrendLineGraph from "../../../components/inventory/highLowDOSTrendLineGraph";

const OverstockInventoryPage = (props: any) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");
  const [tableData, setTableData] = useState(null);
  const [barGraphData, setBarGraphData] = useState(null);
  const [commentValues, setCommentValues] = useState<any>([]);
  const [weeklyBarGraphData , setWeeklyBarGraphData] = useState(null)
  const [barGraphData1, setBarGraphData1] = useState(null);
  const [lineGraphData, setLineGraphData] = useState(null);
  const [lineGraphData1, setLineGraphData1] = useState(null);
  const [waterfallData, setWaterfallData] = useState(null);
  const [highDOSLineGraph, setHighDOSLineGraph] = useState(null);
  const [selectedCluster, setSelectedCluster] = useState("");
  const [selectedRowData, setSelectedRowData] = useState<any>([]);
  const selectedGBPFilter = useSelector(
    (state: any) => state.CommonReducer.selectedGBPFilter
  );

  const getOverstockChartDataHandler = () => {
   
    getOverstockChartData({...selectedGBPFilter,alertType:Number(type)})
      .then((response: any) => {
        if (response?.status === 200) {
          setBarGraphData(response?.data?.data);
          const formattedData = response?.data?.overstockData.map((item: any) => {
            const date = moment(item.WeekStartDate.slice(0, 10)).format("MMM DD");
            return { ...item, date };
          });
          setWeeklyBarGraphData(formattedData)
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };





  const getOverstockLineGraphDataHandler = (cluster:string='') => {
    let payload
    if(cluster!==''){
      payload={...selectedGBPFilter,clustername:[cluster]}
    }else{
      payload=selectedGBPFilter
    }
    getDemandInventoryLineGraphData({...payload,alertType:type==='0'?'highstock':(type==='2'?'unconstrained':'highDos')})
      .then((response: any) => {
        if (response?.status === 200) {
         
          response?.data?.demand?.forEach((value: any, index: number) => {
            value.date = moment(value?.dayDate?.slice(0, 10)).format("MMM'YY");
           
          });
          response?.data?.inventory?.forEach((value: any) => {
            value.date = moment(value?.monthdate?.slice(0, 10)).format(
              "MMM'YY"
            );
          });
          response?.data?.repData?.forEach((value: any) => {
            value.date = moment(value?.monthdate?.slice(0, 10)).format(
              "MMM'YY"
            );
          });
          let finalData1: any = [
            {
              name: "OnHand",
              uv: response?.data?.highOverStock[0]?.onhand,
              pv: 0,
              total:response?.data?.highOverStock[0]?.onhand
            },
            {
              name: "Open EROs",
              uv: response?.data?.highOverStock[0]?.openEro,
              pv: response?.data?.highOverStock[0]?.onhand,
              total:response?.data?.highOverStock[0]?.openEro
            },
            {
              name: "Total Demand Within LT",
              uv: response?.data?.highOverStock[0]?.totaldmd,
              pv: response?.data?.highOverStock[0]?.openEro+response?.data?.highOverStock[0]?.onhand,
              total:response?.data?.highOverStock[0]?.totaldmd
            },
            {
              name: "Inv At End of LT",
              uv: response?.data?.highOverStock[0]?.OverStockatEndOfLT,
              pv: response?.data?.highOverStock[0]?.MaxTargetStock,
              total:response?.data?.highOverStock[0]?.OverStockatEndOfLT+response?.data?.highOverStock[0]?.MaxTargetStock
            },
          ];
          setWaterfallData(finalData1)
          setLineGraphData(response?.data)
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getOverstockPlantChartDataHandler = (data: any,key:number=0) => {
    // if(!barGraphData1){
    
      if(key!==1){
        getOverstockLineGraphDataHandler(data[0]?.plant)
      }
      setSelectedCluster(data[0]?.cluster);
      getOverstockPlantChartData(data[0])
      .then((response: any) => {
        if (response?.status === 200) {
            // let finalData = response?.data?.data?.filter(
            //   (value: any) => value?.plant !== data?.plant
            // );
            // prepareBarGraphData(finalData, 1);
            setBarGraphData1( response?.data?.data)
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    // };
    }

  const getHighLowDOSTrendChartHandler = () => {
    getHighLowDOSTrendChart(selectedGBPFilter)
      .then((response: any) => {
        if (response?.status === 200) {
          response?.data?.data?.forEach((value: any, index: number) => {
            value.date = moment(value?.monthstartdate?.slice(0, 10)).format("MMM'YY");
          })
          setHighDOSLineGraph( response?.data?.data)
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };


  useEffect(() => {
    if(Object.keys(selectedGBPFilter).length !== 0&&type){
      // setLoading(true);
      if((type==='0'||type==='2')){
        getOverstockChartDataHandler();
      }else{
        getHighLowDOSTrendChartHandler()
      }
      
      getOverstockLineGraphDataHandler();
    }
  }, [selectedGBPFilter,type]);

  useEffect(()=>{
    if((type==='0'||type==='2')&&selectedRowData?.length!==0){
      getOverstockPlantChartDataHandler(selectedRowData,1)
    }
    if((type==='0'||type==='2')&&selectedRowData?.length===0){
      setBarGraphData1(null)
      // getOverstockChartDataHandler();
    }
  },[selectedRowData])


  const prepareBarGraphData = (data: any, key: number = 0) => {
    let obj: any = new Object();
    if (key === 1) {
      data?.forEach((value: any) => {
        if (obj.hasOwnProperty(value?.plant)) {
          obj[value?.plant][value?.execSegmentation] += Number(value?.endOfLT);
          obj[value?.plant].sum += Number(value?.endOfLT);
        } else {
          obj[value?.plant] = {
            cluster: value?.plant,
            A: value?.execSegmentation === "A" ? Number(value?.endOfLT) : 0,
            B: value?.execSegmentation === "B" ? Number(value?.endOfLT) : 0,
            C: value?.execSegmentation === "C" ? Number(value?.endOfLT) : 0,
            D: value?.execSegmentation === "D" ? Number(value?.endOfLT) : 0,
            sum: Number(value?.endOfLT),
          };
        }
      });

      let finalData: any = [];

      for (let key in obj) {
        finalData.push(obj[key]);
      }
      finalData.sort((a: any, b: any) => b?.sum - a?.sum);
      setBarGraphData1(finalData);
    } else {
      data?.forEach((value: any) => {
        if (obj.hasOwnProperty(value?.plant_code)) {
          obj[value?.plant_code][value?.execSegmentation] += Number(
            value?.endOfLT
          );
          obj[value?.plant_code].sum += Number(value?.endOfLT);
        } else {
          obj[value?.plant_code] = {
            cluster: value?.plant_code,
            A: value?.execSegmentation === "A" ? Number(value?.endOfLT) : 0,
            B: value?.execSegmentation === "B" ? Number(value?.endOfLT) : 0,
            C: value?.execSegmentation === "C" ? Number(value?.endOfLT) : 0,
            D: value?.execSegmentation === "D" ? Number(value?.endOfLT) : 0,
            sum: Number(value?.endOfLT),
          };
        }
      });

      let finalData: any = [];

      for (let key in obj) {
        finalData.push(obj[key]);
      }
      finalData.sort((a: any, b: any) => b?.sum - a?.sum);
      setBarGraphData(finalData);
    }
  };

  const handleBack=()=>{
    setBarGraphData1(null);
    setSelectedCluster("");
    getOverstockLineGraphDataHandler('')
  }

  return (
    <>
      <p className="text-mainGreen text-1.5vw text-center ">
        {type === "0"
          ? `Unconstrained Overstok withnin LT`
          : (type === "2"?'Constrained Overstock':`High Days of Inventory (Last 4 Months)`)}
        ​
      </p>
      <div className=" mt-2">
        {loading && (
          <Loader backdrop size="md" inverse center content="loading..." />
        )}

        {!loading && (
          <div className="w-full flex justify-center">
            <div className="w-[95%]">
              <div className=" grid grid-cols-2 gap-6">
                {(type==='0')&&<>
                <OverstockInventoryBarGraph
                  goBack={() => handleBack()}
                  selectedCluster={selectedCluster}
                  onbarClick={getOverstockPlantChartDataHandler}
                  barGraphData={barGraphData}
                  barGraphData1={barGraphData1}
                />
                <OverstockInventoryLineGraph
                weeklyBarGraphData = {weeklyBarGraphData}
                  lineGraphData={lineGraphData}
                  lineGraphData1={lineGraphData1}
                  waterfallData={waterfallData}
                  graphType={false}
                />
                </>}

                {type === '2' && <>
                  <OverstockInventoryLineGraph
                weeklyBarGraphData = {weeklyBarGraphData}
                  lineGraphData={lineGraphData}
                  lineGraphData1={lineGraphData1}
                  selectedCluster = {selectedCluster}
                  selectedRowData={selectedRowData}
                  selectedGBPFilter={selectedGBPFilter}
                  waterfallData={waterfallData}
                  graphType={false}
                />
                  <OverstockInventoryBarGraph
                  goBack={() => handleBack()}
                  selectedCluster = {selectedCluster}
                  selectedRowData={selectedRowData}
                  selectedGBPFilter={selectedGBPFilter}
                  onbarClick={getOverstockPlantChartDataHandler}
                  barGraphData={barGraphData}
                  barGraphData1={barGraphData1}
                /></>
                }
                {type==='1'&&<HighLowDOSTrendLineGraph
                  lineGraphData={highDOSLineGraph}
                  graphType={2}
                  />}
                  {type==='1'&&<StockoutInventoryLineGraph
                    lineGraphData={lineGraphData}
                    // graphType={2}
                  />}
              </div>
              <div className="w-full flex justify-center items-center">
                <div className="w-full mt-[0.5dvw]">
                  <OverstockInventoryTable  commentValues={commentValues} selectedRowData={(data:any)=>setSelectedRowData(data)} tableData={tableData} selectedCluster={selectedCluster}/>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OverstockInventoryPage;
