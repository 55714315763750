import React, { useState } from "react";
import {
  Bar,
  BarChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useSelector } from "react-redux";
import { Button, ButtonGroup, ButtonToolbar } from "rsuite";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";

const InactiveInventoryBarGraph = (props: any) => {
  const {
    barGraphData,
    plantLvlDataForx1x2,
    selectedRowData,
    barGraphData1,
    onbarClick,
    goBack,
    selectedCluster,
    selectedGBPFilter,
    graphType,
  } = props;

  const [GobackBtn, setGobackBtn] = useState(false);
  

  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );
  console.log(barGraphData1,plantLvlDataForx1x2 ,barGraphData);
  return (
    <div className="w-full ">
      <div className="flex">
        <span className="text-white font-medium text-0.95vw ">
        {
         selectedRowData.length > 0 ? `X1 X2 OnHand Qty - ${selectedRowData[0]?.plant}` :  selectedGBPFilter?.plant?.length > 0 ?  `X1 X2 OnHand Qty - ${selectedGBPFilter?.plant}`  : selectedGBPFilter?.clustername?.length > 0 ? `X1 X2 OnHand Qty - ${selectedGBPFilter?.clustername}` : selectedCluster ? `X1 X2 OnHand Qty - ${selectedCluster}` : 'X1 X2 OnHand Qty Breakdown Across Cluster'
         }
        </span>
        {GobackBtn && (
          <Button
            onClick={() => {
              goBack();
              setGobackBtn(false);
            }}
            size="xs"
            appearance="ghost"
            className={` bg-green-500 text-white
                 border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white ml-3`}
          >
            Go Back
          </Button>
        )}
      </div>
      <ResponsiveContainer
        width={"100%"}
        height={windowSize?.width / 6.5}
        className={`border-[1px]  border-mainGray`}
      >
        <BarChart
          width={500}
          height={300}
          data={
            plantLvlDataForx1x2 ? plantLvlDataForx1x2 
              :   barGraphData?.stacked 
          }
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: graphType ? 10 : 30,
          }}
        >
          {!graphType && (
            <XAxis
              fontSize={10}
              stroke="#B5B5B5"
              dataKey="cluster"
              angle={-45}
              dx={0}
              dy={15}
              minTickGap={-200}
              axisLine={false}
            />
          )}
          {graphType && (
            <XAxis fontSize={10} stroke="#B5B5B5" dataKey="label" />
          )}
          {!graphType && (
            <YAxis
              fontSize={10}
              stroke="#B5B5B5"
              tickFormatter={(value: any) => value && `${formatAmount(value)}`}
            />
          )}
          {graphType && (
            <YAxis
              fontSize={10}
              stroke="#B5B5B5"
              tickFormatter={(value: any) => value && `${formatAmount(value)}%`}
            />
          )}
          <Tooltip
            cursor={{ fill: "transparent" }}
            content={($) => <CustomTooltip data={$} />}
          />
          <Legend verticalAlign="top" />
          {!graphType && (
            <Bar
              dataKey="x1"
              name="X1"
              onClick={($:any) => {
                onbarClick({ cluster: $?.cluster },0); setGobackBtn(true);
              }}
              barSize={60}
              stackId="a"
              fill="#4ACB4A"
            ></Bar>
          )}
          {!graphType && (
            <Bar
              dataKey="x2"
              name="X2"
              onClick={($:any) => {
                onbarClick({ cluster: $?.cluster }, 0); setGobackBtn(true);
              }}
              barSize={60}
              stackId="a"
              fill="#A5D88C"
            ></Bar>
          )}

          {graphType && (
            <Bar
              dataKey="value"
              name="Movement %"
              onClick={($:any) => {
                onbarClick({ cluster: $?.cluster } ,0); setGobackBtn(true);
              }}
              barSize={60}
              stackId="a"
              fill="#57A4AD"
            ></Bar>
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;

  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          <>
            <p className="text-white text-0.95vw">
              {payload[0]?.payload?.cluster
                ? payload[0]?.payload?.cluster
                : payload[0]?.payload?.label}
            </p>
            {payload?.map(($: any) => {
              return (
                <>
                  {payload[0]?.payload?.cluster && (
                    <p className="text-white text-0.7vw">
                      {$?.name?.toUpperCase()} : 
                      {formatNumber(parseFloat($?.value))}
                    </p>
                  )}
                  {payload[0]?.payload?.label && (
                    <p className="text-white text-0.7vw">
                      Total :{parseFloat($?.value)?.toFixed(2)}%
                    </p>
                  )}
                </>
              );
            })}
          </>
        </div>
      </>
    );
  }

  return null;
};

export default InactiveInventoryBarGraph;
