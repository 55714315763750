import React, { useEffect, useState } from "react";
import { INACTIVE_REBALANCE_DATA, INACTIVE_REBALANCE_DATA_HEADERS } from "../../constants/dataConstant";
import { Loader } from "rsuite";
import { formatNumber } from "../../utils/commonFunctions";
import { getForecastRebalancingData } from "../../queries/apiQueries";


function OverStockRebalanceLevelTable(props :any) {
  const { rowData } = props;
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any>([]);

  const getRebalanceLevelDataHandler = () => {
    setLoading(true);
    getForecastRebalancingData({ Material: rowData?.material , alertType : 'ConstrainedOverstock' })
      .then((response: any) => {
        if (response?.status === 200) {
          setTableData(response?.data?.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (rowData != null) {
      getRebalanceLevelDataHandler();
    }
  }, [rowData]);

  const filteredData = tableData.filter(
    (item: any) => item.Plant_Code === rowData?.cluster
  );
  const otherData = tableData.filter(
    (item: any) =>
      item.Plant_Code !== rowData?.cluster && item?.BranchType === "Svc"
  );
  //   const otherData = tableData.filter(
  //     (item: any) => item.Plant_Code !== rowData?.cluster
  //   );

  const sortedTableData = [...filteredData, ...otherData];
  const clusterCount = filteredData?.length;
  console.log("filteredData:", filteredData.key);
  console.log("otherData:", otherData);
  console.log("sortedTableData:", sortedTableData);
  return (
    <>
      <div className="h-[20dvw] overflow-y-auto">
        <div className=" overflow-x-auto h-full">
          <table className="w-full relative">
            <thead className="border-l-2 sticky top-0 bg-black">
              <tr>
                <th
                  scope="col"
                  className="px-3 py-2 w-5  text-center bg-black text-white border-b-[1px] border-b-mainGreen"
                >
                  Cluster
                </th>

                {INACTIVE_REBALANCE_DATA_HEADERS.map((ele: any) => {
                  return (
                    <th
                      scope="col"
                      className="px-3 py-2 w-5  text-center bg-black text-white border-b-[1px] border-b-mainGreen"
                    >
                      {ele?.label}
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody className=" h-full">
              {loading && (
                <div className="flex justify-center items-center h-full w-full">
                  <Loader className="top-[10vw]" inverse center size="md" />
                </div>
              )}
              {!loading && (
                <>
                  {filteredData?.map((value: any) => (
                    <>
                      <tr className="bg-mainGray text-white">
                        <td className="px-6 py-4 text-center">
                          {value.Plant_Code === rowData?.cluster
                            ? value.Plant_Code
                            : `${value.Plant_Code} `}
                        </td>
                        {INACTIVE_REBALANCE_DATA.map((ele: any) => (
                          <td className="px-6 py-4">
                            {" "}
                            {typeof value[ele?.key] === "number"
                              ? ele?.key == "OnhandDollarValue_RB"
                                ? `$${formatNumber(value[ele?.key])}`
                                : formatNumber(value[ele?.key])
                              : value[ele?.key]}
                          </td>
                        ))}
                      </tr>
                    </>
                  ))}
                </>
              )}
            </tbody>
          </table>
          {otherData?.length > 0 ? (
            <p className="text-lg my-2 text-center text-white">
              Inventory Details For Other Active Locations{" "}
              <span className="font-bold italic text-white">
                (Outside Cluster - Service Center)
              </span>
            </p>
          ) : (
            <></>
          )}

          {otherData?.length > 0 ? (
            <table className="w-full relative">
              <thead className="border-l-2 sticky top-0 bg-black">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-2 w-5 text-center bg-black text-white border-b-[1px] border-b-mainGreen"
                  >
                    Cluster
                  </th>

                  {INACTIVE_REBALANCE_DATA_HEADERS.map((ele: any) => {
                    return (
                      <th
                        scope="col"
                        className="px-3 py-2 w-5  text-center bg-black text-white border-b-[1px] border-b-mainGreen"
                      >
                        {ele?.label}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              <tbody className=" h-full">
                {loading && (
                  <div className="flex justify-center items-center h-full w-full">
                    <Loader className="top-[10vw]" inverse center size="md" />
                  </div>
                )}
                {!loading && (
                  <>
                    {otherData?.map((value: any) => (
                      <>
                        <tr className="bg-mainGray text-white">
                          <td className="px-8 py-4 text-center">
                            {value.Plant_Code === rowData?.cluster
                              ? value.Plant_Code
                              : `${value.Plant_Code} `}
                          </td>
                          {INACTIVE_REBALANCE_DATA.map((ele: any) => (
                            <td className="px-8 py-4">
                              {" "}
                              {typeof value[ele?.key] === "number"
                                ? ele?.key == "OnhandDollarValue_RB"
                                  ? `$${formatNumber(value[ele?.key])}`
                                  : formatNumber(value[ele?.key])
                                : value[ele?.key]}
                            </td>
                          ))}
                        </tr>
                      </>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default OverStockRebalanceLevelTable