import { Configuration, PopupRequest} from "@azure/msal-browser";
import { AD_appId, AD_tenantID, deploymentURL } from "../config/config";

// Config object to be passed to Msal on creation

const endpoint = deploymentURL;

export const msalConfig: Configuration = {
    auth: {
        clientId: AD_appId||'',
        authority: `https://login.microsoftonline.com/${AD_tenantID}`,
        redirectUri:endpoint,
        postLogoutRedirectUri:endpoint
    },
    cache:{
        cacheLocation:'localStorage',
        storeAuthStateInCookie:false
    },
    system: {

        allowNativeBroker: false // Disables WAM Broker
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["User.Read"]
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};