import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Bar,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";
import { Button } from "rsuite";

function PinactiveRebalanceGraph(props: any) {
  const {
    CurrentAlert,
    RebalanceData,
    goBack,
    plantLvlDataForPiRebalance,
    selectedCluster,
    onbarClickRebalance,
    selectedGBPFilter,
    selectedRowData,
    RebalanceData1,
  } = props;
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );
  const [GoBack, setGoBack] = useState(false);
  console.log("data", RebalanceData,RebalanceData1, plantLvlDataForPiRebalance);

  return (
    <div className="w-full">
      <div className="flex justify-between">
        <span className="text-white font-medium text-0.95vw ">

         {
         selectedRowData.length > 0 ? 'Inventory Details for Other Active Locations (Within the Cluster)' :  selectedGBPFilter?.plant?.length > 0 ?  `Inventory Details for - ${selectedGBPFilter?.plant}`  : selectedGBPFilter?.clustername?.length > 0 ? `Inventory Details for Other Active Locations - ${selectedGBPFilter?.clustername}` : selectedCluster ? `Inventory Details for Other Active Locations - ${selectedCluster}` : 'Inventory Details for Other Active Locations (Within the Cluster)'
         }


        </span>
        {GoBack && (
          <Button
            onClick={() => {
              goBack();
              setGoBack(false);
            }}
            size="xs"
            appearance="ghost"
            className={` bg-green-500 text-white
                 border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white ml-3`}
          >
            Go Back
          </Button>
        )}
      </div>
      {plantLvlDataForPiRebalance?.length === 0 ? (
        <><div className="flex h-full justify-center items-center font-bold text-white">No Active Location within the Cluster</div></>
      ) : (RebalanceData != null && RebalanceData.length > 0) ||
        plantLvlDataForPiRebalance != null ? (
        <ResponsiveContainer
          width={"100%"}
          height={windowSize?.width / 6.5}
          className={`border-[1px]  border-mainGray`}
        >
          <ComposedChart
            width={500}
            height={300}
            data={
              plantLvlDataForPiRebalance
                ? plantLvlDataForPiRebalance
                : RebalanceData
            }
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 30,
            }}
          >
            <XAxis
              fontSize={10}
              stroke="#B5B5B5"
              dataKey="plant_code"
              angle={-45}
              dx={0}
              dy={15}
              minTickGap={-200}
              axisLine={false}
            />
            <YAxis
              stroke="#B5B5B5"
              fontSize={10}
              tickFormatter={(value: any) => `${formatAmount(value)}`}
            />
            <YAxis
              yAxisId="right-axis"
              stroke="#B5B5B5"
              fontSize={10}
              hide={false}
              orientation="right"
            />
            <Tooltip
              cursor={{ fill: "transparent" }}
              content={($) => <CustomTooltip data={$} />}
            />
            <Legend verticalAlign="top" />

            <Bar
              dataKey="onhand"
              name="OnHand (Qty)"
              onClick={($:any) => {
                onbarClickRebalance({ cluster: $?.plant_code }, 3);
                setGoBack(true);
              }}
              barSize={60}
              stackId="a"
              fill="#57A4AD"
            />
            <Line
              strokeWidth={3}
              type="monotone"
              name="DOH"
              dataKey="doh"
              yAxisId="right-axis"
              stroke="#82ca9d"
            />
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <><div className="flex h-full justify-center items-center font-bold text-white">NO DATA AVAILABLE</div></>
      )}
    </div>
  );
}

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;
  console.log(payload);

  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          <>
            <p className="text-white text-0.95vw">
              {props?.graphTab == 1
                ? payload[0]?.payload?.cluster
                : payload[0]?.payload.plant_code}
            </p>
            <>
              {payload[0]?.payload?.onhand && (
                <p className="text-white text-0.7vw">
                  On Hand (Qty) :{formatAmount(payload[0]?.payload?.onhand)}
                </p>
              )}
              {payload[0]?.payload?.doh && (
                <p className="text-white text-0.7vw">
                  DOH :{parseFloat(payload[0]?.payload?.doh)}
                </p>
              )}
            </>
          </>
        </div>
      </>
    );
  }

  return null;
};

export default PinactiveRebalanceGraph;
