import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Bar,
  BarChart,
  Brush,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getInactiveReportChartData } from "../../queries/apiQueries";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";
import { Button, ButtonGroup, ButtonToolbar } from "rsuite";

const ObsoleteMaterialStackGraph = () => {
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );

  const [barData, setBarData] = useState([]);
  const [vendorData, setvendorData] = useState([]);
  const [maxValue, setMaxValue] = useState(600000);
  const [graphTab, setGraphTab] = useState(1);
  const getbarData = () => {
    getInactiveReportChartData({ alertType: Number(type) }).then((response) => {
      if (response?.status === 200) {
        setBarData(response?.data?.plantwise);

        const maxOnHandValue = Math.max(
          ...(response?.data?.plantwise?.map(
            (item: any) => item.onhand$ || 0
          ) ?? [])
        );
        setvendorData(response?.data?.vendorwise)
        setMaxValue(maxOnHandValue);
      }
    });
  };

  const CustomTraveler = (props: any) => {
    const { x, y, width, height, stroke, fill } = props;
    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          stroke={stroke}
          fill="red"
          opacity={0.5}
        />
      </g>
    );
  };

  useEffect(() => {
    getbarData();
  }, []);

  const startIndex = 0;
  const endIndex = Math.min(8, barData.length - 1);
  return (
    <div className="w-full overflow-auto">
      <div className="flex w-full justify-between">
        <div className="flex">
          <span className="text-white font-medium text-0.95vw ">
            {`${
              type === "0"
                ? "Clusterwise Obsolete Breakdown ($)"
                : type === "2"
                ? graphTab === 1 ? "Clusterwise Impact($) Breakdown" : " Vendor Data"
                : type === "3"
                ? graphTab === 1  ? "Clusterwise Open Qty ($) Breakdown" : " Vendor Data"
                : "Clusterwise DNO & Excess Inventory Breakdown ($)"
            } `}
          </span>
        </div>
        {(type === "2" || type === "3") && (
 <ButtonToolbar className="pb-1 ">
 <ButtonGroup>
   <Button
     onClick={() => {
       setGraphTab(1);
     }}
     size="xs"
     appearance="ghost"
     className={`${
       graphTab === 1
         ? "bg-green-500 text-white"
         : "bg-white text-black"
     } border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
   >
  {type === '3' ?  "Open Qty ($)":  "Impact ($)"} Breakdown
   </Button>
   {/* <Button
       onClick={() => setGraphTab(2)}
       appearance="ghost"
       size="xs"
       className={`${
         graphTab === 2
           ? "bg-green-500 text-white"
           : "bg-white text-black"
       } border-black border-l-2  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
     >
       Inventory Trend
     </Button> */}
   <Button
     onClick={() => setGraphTab(2)}
     appearance="ghost"
     size="xs"
     className={`${
       graphTab === 2
         ? "bg-green-500 text-white"
         : "bg-white text-black"
     } border-black border-l-2  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
   >
   Vendor Data            </Button>
 </ButtonGroup>
</ButtonToolbar>
        )}
       
      </div>
      <ResponsiveContainer
        width={"100%"}
        height={windowSize?.width / 6.5}
        className={`border-[1px]  border-mainGray`}
      >
        <BarChart
          width={500}
          height={300}
          data={graphTab === 1 ? barData  : vendorData}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 15,
          }}
          barCategoryGap={50}
        >
        {graphTab === 1 && ( <XAxis
            fontSize={10}
            tickMargin={8}
            stroke="#B5B5B5"
            dataKey="Plant_Code"
          />)}
          {graphTab === 2 && ( <XAxis 
               fontSize={10}
            tickMargin={8}
            angle={-30}
            stroke="#B5B5B5"
            dataKey="vendor_code"/>)}
          <YAxis
            fontSize={10}
            stroke="#B5B5B5"
            domain={[0, maxValue]}
            tickCount={7}
            // interval={0}
            tickFormatter={(value: any) => `$${formatAmount(value)}`}
          />

          <Tooltip
            cursor={{ fill: "transparent" }}
            content={($) => <CustomTooltip data={$} type={type} graphTab ={graphTab} />}
          />
          <Legend verticalAlign="top" />
         {graphTab === 1 && ( <Bar
            dataKey="onhand$"
            name={`${
              type === "0"
                ? "OnHand ($)"
                : type === "2"
                ? "Impact ($)"
                : type === "3"
                ? "Open Qty ($)"
                : "Constrained Overstock ($)"
            } `}
            barSize={50}
            stackId="a"
            fill="#57A4AD"
          ></Bar>)}
{
         graphTab === 1 && ( <Brush
            dataKey="Plant_Code"
            alwaysShowText={false}
            startIndex={startIndex}
            endIndex={endIndex}
            height={6}
            tickFormatter={(tick) => (0 ? tick : "")}
            opacity={1}
            fill="black"
            strokeOpacity={1}
            fillOpacity={1}
            stroke="white"
            travellerWidth={0}
          />)}

          {graphTab === 2 && (
            <Bar
            name={`${type == '2' ? 'Impact ($)' : 'Open Qty ($)'} `}
            dataKey="dollarValue"
            stroke="#7AB2D3"
            fill="#7AB2D3"
            barSize={30}
            strokeWidth={3}
             ></Bar>
          )}


        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const CustomTooltip = (props: any) => {
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");
  const { active, payload } = props.data;
  console.log(payload);

  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          {props.graphTab === 1 && (

          <>
            {payload?.map(($: any) => {
              return (
                <>
                  {payload[0]?.payload?.Plant_Code && (
                    <p className="text-white text-0.7vw">
                      {$?.payload?.Plant_Code} : {type !== "2" ? "$" : ""}
                      {formatAmount($?.value)}
                    </p>
                  )}
                </>
              );
            })}
          </>
          )}

          {props.graphTab === 2 && (
            <>
 
            <>
              <p className="text-white text-0.7vw">
                             {type == '3' ? 'Open Qty ($)' : 'Impact ($)'} : {formatAmount(payload[0]?.payload?.dollarValue)}
                            </p>
            </>
        </>
          )}
        </div>
      </>
    );
  }

  return null;
};

export default ObsoleteMaterialStackGraph;
