import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Modal,
  Notification,
  Pagination,
  Table,
  useToaster,
} from "rsuite";
import InfoIcon from '@mui/icons-material/Info';
import { INACTIVE_INV_TABLE } from "../../constants/dataConstant";
import { useLocation } from "react-router-dom";
import {
  getInactiveInventoryData,
  updateInventoryComments,
  updateReviewedInventoryData,
} from "../../queries/apiQueries";
import { formatNumber } from "../../utils/commonFunctions";
import { CSVLink } from "react-csv";
import { TABLEFILTER } from "../forecast/forecastDataTable";
import RebalanceLevelTable from "./RebalanceLevelTable";

const { Column, HeaderCell, Cell } = Table;
const leftStick = ["plant", "material"];
const rightStick = ["isReviewed", "RecommendedAction", "comme" ,"RecommendedAction_TrueInactive"];

const InactiveInventoryTable = (props: any) => {
  const { selectedCluster } = props;

  const toaster = useToaster();
  const location = useLocation();
  const [selectedSKUL, setSelectedSKUL] = useState<any>(null);
  const [openModal,setOpenModal]=useState(false)
  const [saveButton,setSaveButton]=useState(true)
  const [ChangeData,setChangeData]=useState<any>(null)
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );
  const [tableHeader, setTableHeader] = useState(INACTIVE_INV_TABLE);
  const [tableData, setTableData] = useState(null);
  const [count, setCount] = useState(0);
  const [exportAllData, setExportALLData] = useState<any>([]);
  const [isExport, setIsExport] = useState(false);
  const exportFullRef = useRef<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedRowData, setSelectedRowData] = useState<any>([]);
  const [commentValues, setCommentValues] = useState<any>([]);
  const didMount = useRef(false);
  const [coundtHandler, setCountHandler] = useState(0);
  const selectedGBPFilter = useSelector(
    (state: any) => state.CommonReducer.selectedGBPFilter
  );
  const [changedComments, setChangedComments] = useState<{ data: Array<any> }>({ data: [] });
  const type = new URLSearchParams(location.search).get("type");

  const getInactiveInventoryDataHandler = () => {
    if (isExport) {
      toaster.push(message, { placement: "topCenter" });
    } else {
      setLoading(true);
    }
    let payload = {
      ...selectedGBPFilter,
      alertType: Number(type),
      pageNumber: pageNumber || 1,
      pageSize: 100,
      isExport: isExport,
    };

    if (selectedCluster !== "") {
      payload = { ...payload, clustername: [selectedCluster] };
    }
  

    getInactiveInventoryData(payload)
      .then((response: any) => {
        if (response?.status === 200) {
          if (!isExport) {
            setTableData(response?.data?.data);
            setCount(response?.data?.count);
          } else {
            prepareExportData(response?.data?.data);
          }
        }

        setLoading(false);
        setIsExport(false);
      })
      .catch((err) => {
        setLoading(false);
        setIsExport(false);
        console.log(err);
      });
  };

  const HandleCommentonChange = (data: any) => {
    console.log(data);
    const existingIndex = commentValues.findIndex(
      (item: { SKUl: any }) => item.SKUl === data.SKUl
    );
    if (existingIndex > -1) {
      const updatedcommentValues = [...commentValues];
      updatedcommentValues[existingIndex] = {
        ...updatedcommentValues[existingIndex],
        ...data,
      };
      setCommentValues(updatedcommentValues);
    } else {
      setCommentValues([...commentValues, data]);
    }
    console.log("Updated Resolution Data:", commentValues);
  };

  useEffect(() => {
    if (type && pageNumber && selectedRow?.length === 0) {
      if (Object.keys(selectedGBPFilter).length !== 0) {
        getInactiveInventoryDataHandler();
      }
    }
  }, [type, pageNumber, selectedGBPFilter, selectedCluster]);

  useEffect(() => {
    if (isExport) {
      getInactiveInventoryDataHandler();
    }
  }, [isExport]);

  useEffect(() => {
    let table = [...INACTIVE_INV_TABLE];
    if (type === "0") {
      let obj = [
        {
          label: "Total Demand within LT Custom",
          key: "openSalesOrders",
        },
        {
          label: "Previous Month Comments",
          key: "PreviousMonthComments",
        },
        
        {
          label:'Current Month Comments',
          key:'Comments'
      },
        {
          label: "Potential Actions",
          key: "RecommendedAction",
        },
    
        {
          label: "Review Status",
          key: "isReviewed",
        },
      ];
      obj.forEach((value) => {
        table.push(value);
      });
      setTableHeader(table);
    } else if (type === "1") {
      let obj = [
        {
          label: "Total Demand within LT Custom",
          key: "openSalesOrders",
        },
        {
          label: "Inventory Age",
          key: "InventoryAgeBuckets",
        },
        {
          label: "Last Received Date  ",
          key: "Lst_Rc_Dt",
        },
        {
          label: "Previous Month Comments",
          key: "PreviousMonthComments",
        },
        {
          label:'Current Month Comments',
          key:'Comments'
      },
        {
          label: "Potential Actions",
          key: "RecommendedAction_TrueInactive",
        },
        {
          label: "Review Status",
          key: "isReviewed",
        },
      ];

      obj.forEach((value) => {
        table.push(value);
      });
      setTableHeader(table);
    } else if (type === "2") {
      let obj = [
        {
          label: "Inventory Age",
          key: "InventoryAgeBuckets",
        },
        {
          label: "Last Received Date ",
          key: "Lst_Rc_Dt",
        },
        {
          label: "Previous Month Comments",
          key: "PreviousMonthComments",
        },
        {
          label:'Current Month Comments',
          key:'Comments'
      },
        {
          label: "Potential Actions",
          key: "RecommendedAction_TrueInactive",
        },
        {
          label: "Review Status",
          key: "isReviewed",
        },
      ];
      obj.forEach((value) => {
        table.push(value);
      });
      setTableHeader(table);
    }
  }, [type]);

  useEffect(() => {
    if (exportAllData?.length !== 0) {
      exportFullRef?.current?.link.click();
    }
  }, [exportAllData]);

  const prepareExportData = (data: any) => {
    if (data) {
      
      data?.forEach((element: any) => {
        element.onHand = formatNumber(element?.onHand);
        element.onHand$ = "$" + formatNumber(element?.onHand$);
        element.Lst_Rc_Dt = element?.Lst_Rc_Dt?.slice(0, 10);
        if (type === "0") {
          delete element.InventoryAgeBuckets;
          delete element.Lst_Rc_Dt;
        } else if (type === "1") {
          // delete element.InventoryAgeBuckets
          delete element.openSalesOrders;
        } else if (type === "2") {
          delete element.openSalesOrders;
        }
      });
    }

    setExportALLData(data);
  };

  const handleRowSelection = (data: any) => {
    let index = selectedRow.indexOf(data?.SKUl);
    if (index !== -1) {
      // selectedRow.splice(index, 1);
      setSelectedRow([]);
      setSelectedRowData([]);
    } else {
      setSelectedRow([data?.SKUl]);
      setSelectedRowData([data]);
    }
  };

  useEffect(() => {
    if (didMount.current) {
      if (coundtHandler >= 1) {
        props?.onRowChange(selectedRowData);
        props?.selectedRowData(selectedRowData)
        
      } else {
        setCountHandler(coundtHandler + 1);
      }
    } else {
      didMount.current = true;
      setCountHandler(coundtHandler + 1);
    }
  }, [selectedRowData]);

  const handleReviewChange = (data: any) => {
    updateReviewedInventoryData(data)
      .then((response) => {
        if (response?.status === 200) {
          getInactiveInventoryDataHandler();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const FormatCommentData = (data : any) => {
    console.log(data);
    
    let found = false,commentdata  = changedComments.data
    commentdata.forEach((item : any) => {
      if (item?.SKUl === data?.SKUl) {
        item.Comments = data?.Comments;
        found = true;
      }
    })
    
    if(!found) {
      commentdata.push({
        SKUl: data?.SKUl,
        Comments: data?.Comments
      })
    }

    
    setSaveButton(commentdata.length !== 0  ? false : true);
    setChangedComments({ data: commentdata });
  }
 

  

  const updatecomments = () => {
    

    updateInventoryComments({data : commentValues , alertType : 'inactive'})
    .then((response) => {
      if (response?.status === 200) {
        setLoading(true);
        getInactiveInventoryDataHandler()
        setSaveButton(false)
      }
    })
    .catch((err) => {
      console.log(err);
    });
    
  }

  const HandleCommentChange = (data : any) => {
    
    let obj = {
      SKUl: data?.SKUl,
      type: data?.type,
      Comments: data?.newValue,
    };
    setChangeData(obj);
  }
  useEffect(()=>{
    if(ChangeData){
      FormatCommentData(ChangeData);
    }
  }, [ChangeData]);

  return (
    <>
      <Table
        height={windowSize?.height / 3.4}
        data={tableData ? tableData : []}
        loading={loading}
        bordered={true}
        className="border-mainGray mt-2"
        cellBordered={true}
        headerHeight={65}
        virtualized
        hover={false}
      >
        {tableHeader?.map((value) => {
          
          return (
            <Column
  
              fixed={
                rightStick.includes(value?.key)
                  ? "right"
                  : leftStick.includes(value?.key)
                  ? "left"
                  : false
              } 
     
              width={(value?.key==='RecommendedAction_TrueInactive' || value?.key==='RecommendedAction' )? windowSize?.width/6 : windowSize?.width/15}
              key={value?.key}
              fullText
              flexGrow={value?.key==='RecommendedAction_TrueInactive' || value?.key==='RecommendedAction' ? 0 : 0}
            >
              <HeaderCell
                style={{
                  backgroundColor: "black",
                  color: "white",
                }}
                className="text-0.7vw text-center border-[#333231]"
              >
                <p className="text-wrap">{value?.label}</p>
              </HeaderCell>
              <DataCell
                handleReviewChange={(data: any) => {
                  handleReviewChange(data);
                }}
                dataKey={value?.key}
                selectedRow={selectedRow}
                HandleCommentonChange ={(data : any) =>{
                  HandleCommentonChange(data);
                 }}

                handleCommentChange={(data : any) => {
                  HandleCommentChange(data);
                }}
                handleRowSelection={(data: any) => {
                  handleRowSelection(data);
                }}
                setOpenModal={(data :any) =>{setOpenModal(true); setSelectedSKUL(data)}}
              />
            </Column>
          );
        })}
      </Table>
      <div className="w-full flex justify-between p-2">
        <div className="w-3/12"></div>
        <div className="w-1/2 flex justify-center">
          <Pagination
            ellipsis
            boundaryLinks
            maxButtons={5}
            last
            next
            first
            size="xs"
            total={count}
            limit={100}
            layout={["pager", "skip"]}
            activePage={pageNumber}
            onChangePage={(page: any) => {
              setPageNumber(page);
            }}
          />
        </div>
        <div className="w-3/12 flex justify-end">
        <Button
            onClick={updatecomments}
            className="w-20 mr-2"
            color="green"
            appearance="primary"
          >
            Save
          </Button>
          <Button
            onClick={() => setIsExport(true)}
            className="w-20"
            color="green"
            appearance="primary"
          >
            Export
          </Button>
          <CSVLink
            data={exportAllData}
            ref={exportFullRef}
            headers={tableHeader}
            filename={
              type === "0"
                ? "Potential_Inactive"
                : type === "1"
                ? "Untagged_Inactive"
                : "Ageing_Inactive"
            }
            className="hidden"
          ></CSVLink>
        </div>
      </div>

      <Modal size={'65vw'} open={openModal} onClose={()=>{setOpenModal(false);setSelectedSKUL(null)}}>
        <Modal.Header>
          <Modal.Title className="text-center text-white">Inventory Details For Other Active Locations   <span className="font-bold italic text-white">(Within Cluster)</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Placeholder.Paragraph rows={size === 'full' ? 100 : 10} /> */}
          <RebalanceLevelTable rowData = {selectedSKUL} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>{setOpenModal(false);setSelectedSKUL(null)}} className="bg-red-600 text-white focus:bg-red-900" appearance="subtle">
            Close
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
};

const DataCell = ({ rowData, dataKey,setOpenModal, ...props }: any) => {
  let changeKey = ["onHand", "onHand$", "Lst_Rc_Dt"];

  const handleRowSelection = (data: any) => {
    props?.handleRowSelection({
      SKUl: data?.material + data?.plant,
      plant: data?.plant,
      cluster : data?.cluster,
      material: data?.material,
    });
  };

  const handleTextareaChange = (rowData: any, key: string, value: string) => {
    props.HandleCommentonChange({
      SKUl: rowData["SKUl"],
      [key]: value, 
    });
  };

  const handleReviewClick = (data: any, check: number) => {
    props.handleReviewChange({
      SKUl: data?.SKUl,
      reviewedFlag: check,
      alertType: "inactive",
    });
  };

 

  return (
    <Cell
      {...props}
      className="text-white"
      onClick={() => {
        handleRowSelection(rowData);
      }}
      style={{
        backgroundColor:
          props?.selectedRow[0] === rowData?.material + rowData?.plant
            ? "#56544e"
            : "#1a1916",
      }}
    >
      {dataKey === "Comments" && (
       <textarea
       onClick={(e)=>e.stopPropagation()}
       key={rowData[dataKey]}
       defaultValue={rowData[dataKey]}
       onChange={(e) => handleTextareaChange(rowData, dataKey, e.target.value)}
       className="w-full text-[12px] border-[0.5px] text-black rounded-lg h-[35px] relative top-[-10px] px-2 border-gray-700 "

     />
      )}
    {dataKey === "material" && ( 
        <p className="truncate text-0.7vw">
          {rowData[dataKey]} 

          {rowData?.RecommendedAction_TrueInactive?.includes('Rebalance') ? ( // Check if 'PotentialActions' includes 'rebalance'
            <span onClick={(e) => { setOpenModal({material : rowData?.material , cluster : rowData?.cluster}) ; e.stopPropagation() }}>
              <InfoIcon className="ml-2" sx={{ fontSize: { xs: 15, sm: 15, md: 15, lg: 15 } }} />
            </span>
          ) : <></>}
        
        </p>
      )}
      {dataKey !== "isReviewed" ? (
        <>
          {!changeKey?.includes(dataKey) && (
            <p className="truncate  text-0.7vw">
              {rowData[dataKey] !== null ? rowData[dataKey] : ""}
            </p>
          )}
          {dataKey === "Lst_Rc_Dt" && (
            <p className="truncate  text-0.7vw">
              {rowData[dataKey] !== null ? rowData[dataKey].slice(0, 10) : ""}
            </p>
          )}
          {dataKey === "onHand" && (
            <p className="truncate  text-0.7vw">
              {rowData[dataKey] !== null ? formatNumber(rowData[dataKey]) : ""}
            </p>
          )}
          {dataKey === "onHand$" && (
            <p className="truncate  text-0.7vw">
              {rowData[dataKey] !== null
                ? "$" + formatNumber(rowData[dataKey])
                : ""}
            </p>
          )}
        </>
      ) : (
        <ButtonToolbar className="pb-1 ">
          <ButtonGroup>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleReviewClick(rowData, 0);
              }}
              size="xs"
              appearance="ghost"
              className={`${
                rowData[dataKey] === 0
                  ? "bg-gray-500 text-white"
                  : "bg-white text-black"
              } border-black border-l-2 hover:bg-green-900 hover:text-white  text-xs`}
            >
              N
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleReviewClick(rowData, 1);
              }}
              appearance="ghost"
              size="xs"
              className={`${
                rowData[dataKey] === 1
                  ? "bg-yellow-500 text-white"
                  : "bg-white text-black"
              } border-black border-l-2  hover:bg-green-900 hover:text-white text-xs `}
            >
              IP
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleReviewClick(rowData, 2);
              }}
              appearance="ghost"
              size="xs"
              className={`${
                rowData[dataKey] === 2
                  ? "bg-green-500 text-white"
                  : "bg-white text-black"
              } border-black  hover:bg-green-900 hover:text-white  text-xs`}
            >
              Y
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      )}
    </Cell>
  );
};

const message = (
  <Notification type={"info"} header={`Exporting...`} closable>
    <p>Your file is being created. It will be download automatically</p>
  </Notification>
);

const mapDispatchToProps = (dispatch: any) => ({
  onRowChange: (data: any) => dispatch({ type: TABLEFILTER, data: data }),
});

export default connect(null, mapDispatchToProps)(InactiveInventoryTable);
