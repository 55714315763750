import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button, ButtonGroup, ButtonToolbar, Pagination, Table } from "rsuite";

import { useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";
import moment from "moment";
import { tableHeader1, tableHeader2, tableHeaders, tableHeaders3 } from "../../constants/dataConstant";



const rightStick = [
  "isReviewed",
  "Current_Month_Status",
  "Current_Month_Resolution_Activities",
  "Comments"
];
const leftStick = ["Material", "Plant", "District_Name", "Plant_Code"];
function DataCell({
  rowData,
  dataKey,
  onRowSelect,
  onReviewChange,
  handleReviewChange,
  handleTextareaChange,
  ...props
}: {
  rowData: any;
  dataKey: string;
  handleTextareaChange : any;
  handleReviewChange :any;
  onRowSelect: (selectedData: {
    sku: string;
    plant: string;
    cluster: string;
    material: string;
  }) => void;
  onReviewChange: (material: string, status: string) => void;
}) {
  const handleRowSelection = () => {
    const selectedData = {
      sku: `${rowData.material}-${rowData.plant}`,
      plant: rowData.plant,
      cluster: rowData.cluster,
      material: rowData.material,
    };
    onRowSelect(selectedData);
  };

  const formatNumberKey = [
    "ConstrainedOverstockQty$",
    "ConstrainedOverstockQty",
    "OnHand",
    "Rounding_Value",
    "Onhand$Value",
    "Request_Qty",   
    "Unshipped", 
    "Open_Qty",
    "Onhand",
    "On_Hand",
    "Open_Qty_$",
    "Overstock_Qty",
     "ERO_Date_Diff",
    "ERO_Age",
    "Cost_EA",
    "Unit_Cost",
    "Impact",
    "Rcmd_Qty"  ]

    const PlaceHolderKey = [
      "SKUl_Check",
      "isReviewed"
    ]

  const formatAmountKey = [
    "Repl_LT",
    
    "Cost_FOB",
   
    // "Calc_LT",
  ]
  const dateKey = [
    "RecommendedDate",
    "CurrentDate",
    "CreateDate",
    "End_of_LT_Date"
  ];
  const handleReviewClick = (status: string) => {
    onReviewChange(rowData.material, status);
  };
  const displayValue = rowData[dataKey] != null ? rowData[dataKey] : "-";

  return (
    <>
     {!PlaceHolderKey?.includes(dataKey) && !formatAmountKey?.includes(dataKey)  && !formatNumberKey?.includes(dataKey) && !dateKey?.includes(dataKey) &&  (
            <p className="bg-[#1a1916] text-0.7vw text-white">
              {rowData[dataKey] !== null ? rowData[dataKey] : "-"}
            </p>
          )}

  {dateKey?.includes(dataKey)  && (
              <p className="bg-[#1a1916] text-0.7vw text-white">
                {rowData[dataKey] !== null
                  ? moment(rowData[dataKey]?.slice(0, 10))?.format("MM-DD-YYYY")
                  : ""}
              </p>
            )}
 {formatNumberKey?.includes(dataKey)&&(
             <div className="bg-[#1a1916] text-0.7vw text-white">
          {dataKey === "Open_Qty_$" || dataKey === "Onhand$Value" || dataKey === "Unit_Cost" || dataKey === "Cost_EA" || dataKey === "Impact" ? "$" : ""}   {formatNumber(rowData[dataKey])}
           </div>
          )}
 {formatAmountKey?.includes(dataKey)&&(
             <div className="bg-[#1a1916] text-0.7vw text-white">
                  {   dataKey ==  "Cost_FOB" ? "$" : ""} {parseFloat(rowData[dataKey]).toFixed(1)}
           </div>
          )}



 {dataKey === "SKUl_Check" &&(
                  
                    <div className="bg-[#1a1916] text-0.7vw text-white flex justify-center items-center">
                      {rowData[dataKey] === "Yes" ? (
                       '✓'
                      ) : (
                       '✘'
                      )}
                    </div>
                )}


{dataKey === "isReviewed" && (
        <ButtonToolbar className="pb-1 ">
        <ButtonGroup>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleReviewChange(rowData.SKUl, 0);
            }}
            size="xs"
            appearance="ghost"
            className={`${
              rowData[dataKey] === 0
                ? "bg-gray-500 text-white"
                : "bg-white text-black"
            } border-black border-l-2 hover:bg-green-900 hover:text-white  text-xs`}
          >
            N
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleReviewChange(rowData.SKUl, 1);
            }}
            appearance="ghost"
            size="xs"
            className={`${
              rowData[dataKey] === 1
                ? "bg-yellow-500 text-white"
                : "bg-white text-black"
            } border-black border-l-2  hover:bg-green-900 hover:text-white text-xs `}
          >
            IP
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleReviewChange(rowData.SKUl, 2);
            }}
            appearance="ghost"
            size="xs"
            className={`${
              rowData[dataKey] === 2
                ? "bg-green-500 text-white"
                : "bg-white text-black"
            } border-black  hover:bg-green-900 hover:text-white  text-xs`}
          >
            Y
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
)}
          </>
  );
}

function ObsoleteMaterialTable(props: any) {
  const { Column, HeaderCell, Cell } = Table;
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );
  
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");
  const handleRowSelect = (selectedData: any) => {
    console.log("Row selected:", selectedData);
  };
  

  const Theader = type === "0" ? tableHeaders : type === "2" ? tableHeaders3 : type === "3" ? tableHeader2 : tableHeader1;

  const handleTextareaChange = (rowData: any, key: string, value: string) => {
    props.HandleResolutionChange({
      SKUl: rowData["SKUl"],
      [key]: value, 
    });
  };

  return (
    <>
      <Table
        height={
          props?.showTable ? windowSize?.height / 1.6 : windowSize?.height / 3.6
        }
        data={props?.data}
        className="border-mainGray mt-2 "
        bordered
        virtualized
        cellBordered={true}
        headerHeight={65}
        loading={props.loading}
      >
        {Theader.map((header) => (
          <Column
            key={header.key}
            fixed={
              rightStick.includes(header?.key)
                ? "right"
                : leftStick.includes(header?.key)
                ? "left"
                : false
            }
            fullText
            width={
              header?.key === "Previous_Month_Status"
               || header?.key === "Current_Month_Status" 
               || header?.key === "Current_Month_Resolution_Activities" ||
              header?.key === "Previous_Resolution_Activities" || header?.key === "Comments" || header?.key === "PreviousComments" 
                ? windowSize?.width / 10
                : header?.key === "RecommendedDate" ?  windowSize?.width / 16: windowSize?.width / 15
            }
          >
            <HeaderCell
              style={{
                backgroundColor: "black",
                color: "white",
              }}
              className=" text-0.7vw text-center border-[#333231]"
            >
              <p className="text-wrap">{header.label}</p>
            </HeaderCell>
            <Cell
              style={{
                background: "#1a1916",
                borderRight: "0px solid #333231",
                borderBottom: "0px solid #333231",
              }}
            >
              {(rowData) => {
            if (header.key === "Current_Month_Status" || header.key === "Current_Month_Resolution_Activities" || header.key === "Comments"  ) {
                  return (
                    <textarea
                    onClick={(e) => e.stopPropagation()}
                    defaultValue={rowData[header.key]}
                    className="w-full text-[12px] border-[0.5px] text-black rounded-lg h-[35px] relative top-[-10px] px-2 border-gray-700"
                    onChange={(e) => handleTextareaChange(rowData, header.key, e.target.value)}
                  />
                  );
                }   else {
                  return (
                    <DataCell
                      rowData={rowData}
                      dataKey={header.key}
                      onRowSelect={handleRowSelect}
                      onReviewChange={props.handleReviewChange}
                      handleTextareaChange={handleTextareaChange}
                      handleReviewChange={props.handleReviewChange}
                    />
                  );
                }
              }}
            </Cell>
          </Column>
        ))}
      </Table>
      <div className="w-full flex justify-center p-2">
        <div className="w-3/12"></div>
        <div className="w-1/2 flex justify-center">
          <Pagination
            ellipsis
            boundaryLinks
            maxButtons={5}
            last
            next
            first
            size="xs"
            total={props.count}
            limit={100}
            layout={["pager", "skip"]}
            activePage={props.pageNumber}
            onChangePage={(page: any) => {
              props.setPageNumber(page);
            }}
          />
        </div>
        <div className="w-3/12 flex justify-end">
        <Button
            onClick={props.updateForecast}
            className="w-20 mr-2"
            color="green"
            appearance="primary"
          >
            Save
          </Button>
          <Button
            onClick={() => {
              props?.setIsExport(true);
            
            }}
            className="w-20"
            color="green"
            appearance="primary">
            Export
          </Button>
          <CSVLink
            data={props?.exportAllData}
            ref={props?.exportFullRef}
            headers={type === "1" ? tableHeader1  :(type==='0'?tableHeaders:type === '3' ? tableHeader2 : tableHeaders3)}
            filename={type === "0" ? "Obsolete Materials" : (type==='1'?"DNO Materials": type === '3' ? 'Open Velocity code' : 'Open PO Expedite')}
            className="hidden"
          ></CSVLink>
        </div>
      </div>
    </>
  );
}



export default ObsoleteMaterialTable;
