import React, { useEffect, useState } from "react";
import { Loader } from "rsuite";

import POInventoryLineGraph from "../../../components/inventory/poInventoryLineGraph";
import POInventoryTable from "../../../components/inventory/poInventoryTable";
import { getDemandInventoryLineGraphData } from "../../../queries/apiQueries";
import moment from "moment";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const POInventoryPage = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [lineGraphData, setLineGraphData] = useState(null);
  const [waterfallData, setWaterfallData] = useState(null);
  const [VendorData, setVendorData] = useState(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [TileFilter, setTileFilter] = useState<string[]>([]);

  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");
  const selectedGBPFilter = useSelector(
    (state: any) => state.CommonReducer.selectedGBPFilter
  );

  const getDemandInventoryLineGraphDataHandler = () => {
    let payLoad = { ...selectedGBPFilter, alertType: "potentialOverstock" ,releaseType : TileFilter };

    if (selectedRow) {
      payLoad = { ...payLoad, poNumber: selectedRow?.poNumber };
    }

    getDemandInventoryLineGraphData(payLoad)
      .then((response: any) => {
        if (response?.status === 200) {
          response?.data?.demand?.forEach((value: any, index: number) => {
            value.date = moment(value?.dayDate?.slice(0, 10)).format("MMM'YY");
            value.connect = null;
          });
          response?.data?.inventory?.forEach((value: any) => {
            value.date = moment(value?.monthdate?.slice(0, 10)).format(
              "MMM'YY"
            );
          });
          response?.data?.repData?.forEach((value: any) => {
            value.date = moment(value?.monthdate?.slice(0, 10)).format(
              "MMM'YY"
            );
          });

          response?.data?.potentialOverstock.forEach((value: any) => {
            if (response?.data?.potentialOverstock.length > 1) {
              value.date = moment(value?.monthstartdate?.slice(0, 10)).format(
                "MMM'YY"
              );
              // }
            } else {
              value.date = moment(value?.monthstartdate?.slice(0, 10)).format(
                "MMM'YY"
              );
            }
          });

          setWaterfallData(response?.data?.potentialOverstock);

          setVendorData(response?.data?.vendorChart);
          setLineGraphData(response?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTileClick = (value: string) => {
    if (TileFilter.length > 0 && TileFilter[0] === value) {
      // If the clicked tile is the same as the currently selected tile, clear the filter
      setTileFilter([]);
    } else {
      // Otherwise, set the filter to the clicked tile
      setTileFilter([value]);
    }
  };

  useEffect(() => {
    if (Object.keys(selectedGBPFilter).length !== 0) {
      getDemandInventoryLineGraphDataHandler();
    }
  }, [selectedGBPFilter , TileFilter]);
  return (
    <>
      <div className="flex justify-center items-center">
        <div className="w-[95%] grid grid-cols-1 gap-4">
          <div className="grid grid-cols-7 gap-4">
            <div className="grid grid-cols-3 gap-4 justify-items-end col-span-2"></div>
            <div className="col-span-3">
              <p className="text-mainGreen mr-2 text-1.5vw text-center">
                Potential Overstock{" "}
                <span className="text-base">(Due to Open POs)</span>
              </p>
            </div>
            <div className="grid grid-cols-4 items-center text-center mt-2  gap-2 justify-items-center col-span-2">
            <div>
                <p className="text-white font-bold text-lg">Releases :</p>
              </div>
            {["Auto", "Manual", "ZFBK/NSR"].map((tile, index) => (
              <div
                key={index}
                onClick={() => handleTileClick(tile)}
                className={`cursor-pointer ${
                  TileFilter.includes(tile) ? "bg-green-600" : "bg-[#2d2d2d]"
                } w-full p-1 pl-1 text-center rounded-md`}
              >
                <p className="text-white font-bold text-sm">{tile}</p>
              </div>
            ))}
            </div>

          </div>
        </div>
      </div>

      <div className=" ">
        {loading && (
          <Loader backdrop size="md" inverse center content="loading..." />
        )}

        <div className="w-full flex justify-center">
          <div className="w-[95%]">
            <div className=" grid grid-cols-2 gap-6">
              <POInventoryLineGraph
                selectedRow={selectedRow}
                selectedGBPFilter={selectedGBPFilter}
                VendorData={VendorData}
                graphType={4}
                waterfallData={waterfallData}
              />
              <POInventoryLineGraph
                selectedRow={selectedRow}
                selectedGBPFilter={selectedGBPFilter}
                lineGraphData={lineGraphData}
              />
            </div>
            <div className="w-full flex justify-center items-center">
              <div className="w-full mt-[0.5dvw]">
                <POInventoryTable
                  selectedRow={(data: any) => setSelectedRow(data)}
                  tileFilter={TileFilter}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default POInventoryPage;
