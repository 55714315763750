import React, { useEffect, useState } from "react";
import {
  Bar,
  ComposedChart,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useSelector } from "react-redux";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";
import MuiTooltip from "@mui/material/Tooltip"; // Default import
import { DUMMY } from "../../constants/dataConstant";
import { Button, ButtonGroup, ButtonToolbar } from "rsuite";
import OverstockWaterFallGraph from "./overstockWaterFallGraph";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const OverstockInventoryLineGraph = (props: any) => {
  const {
    lineGraphData,
    lineGraphData1,
    graphType,
    waterfallData,
    weeklyBarGraphData,
    selectedGBPFilter,
    selectedCluster,
    selectedRowData,
  } = props;

  const [graphTab, setGraphTab] = useState(4);
  const [graphView, setGraphView] = useState(graphType);

  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );

  const lastDataPoint = weeklyBarGraphData?.length > 0 ? weeklyBarGraphData[weeklyBarGraphData?.length - 1] : null;
  const lastDateValue = lastDataPoint && lastDataPoint?.value != null ? lastDataPoint?.value : 0; 
  const lastDate = lastDataPoint ? lastDataPoint?.date : '';
  const formattedMonth = lastDate ? new Date(lastDate).toLocaleString('default', { month: 'short' }) : '';
  console.log(selectedRowData);

  return (
    <>
      <div className="w-full relative top-[-2px]">
        <div className="flex w-full justify-between ">
          <div>
            <span className="text-white font-medium text-0.95vw ">
              {graphTab === 1
                ? selectedRowData?.length > 0
                  ? `On Order vs  Demand (Qty) - ${selectedRowData[0]?.plant} Plant `
                  : selectedGBPFilter?.clustername?.length > 0
                  ? `On Order vs  Demand (Qty) - ${selectedGBPFilter?.clustername[0]} Cluster`
                  : selectedGBPFilter?.plant?.length > 0
                  ? `On Order vs  Demand (Qty) - ${selectedGBPFilter?.plant[0]} Plant`
                  : "On Order vs  Demand (Qty)  "
                : graphTab === 2
                ? "Inventory Trend (Qty)"
                : graphTab === 4
                ? selectedRowData?.length > 0
                  ? `Constrained Overstock (Qty) Trend - ${selectedRowData[0]?.plant} Plant`
                  : selectedGBPFilter?.clustername?.length > 0
                  ? `Constrained Overstock (Qty) Trend - ${selectedGBPFilter?.clustername[0]} Cluster`
                  : selectedGBPFilter?.plant?.length > 0
                  ? `Constrained Overstock (Qty) Trend - ${selectedGBPFilter?.plant[0]} Plant`
                  : "Constrained Overstock (Qty) Trend"
                : selectedRowData?.length > 0
                ? `LT Trend - ${selectedRowData[0]?.plant} Plant`
                : selectedGBPFilter?.clustername?.length > 0
                ? `LT Trend - ${selectedGBPFilter?.clustername[0]} Cluster`
                : selectedGBPFilter?.plant?.length > 0
                ? `LT Trend -  ${selectedGBPFilter?.plant[0]} Plant`
                : "LT Trend"}
            </span>
            {graphTab === 4 && (
              <MuiTooltip placement="top" className="font-bold" title={`Note: Historical Data is only on the SKULs present in ${formattedMonth}`}  arrow>
                <InfoOutlinedIcon
                  className="mb-3 ml-2"
                  sx={{ color: "white" }}
                ></InfoOutlinedIcon>
              </MuiTooltip>
            )}
          </div>
          <ButtonToolbar className="">
            <ButtonGroup>
            {!graphView && (
                <Button
                  onClick={() => setGraphTab(4)}
                  appearance="ghost"
                  size="xs"
                  className={`${
                    graphTab === 4
                      ? "bg-green-500 text-white"
                      : "bg-white text-black"
                  } border-black border-l-2  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
                >
                  Constrained Overstock Trend
                </Button>
              )}
              <Button
                onClick={() => {
                  setGraphTab(1);
                }}
                size="xs"
                appearance="ghost"
                className={`${
                  graphTab === 1
                    ? "bg-green-500 text-white"
                    : "bg-white text-black"
                } border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
              >
                On Order vs Demand
              </Button>
       
              {/* {graphView && ( 
                 <Button
                  onClick={() => setGraphTab(2)}
                  appearance="ghost"
                  size="xs"
                  className={`${
                    graphTab === 2
                      ? "bg-green-500 text-white"
                      : "bg-white text-black"
                  } border-black border-l-2  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
                >
                  Inventory Trend
                </Button> 
               )}  */}

              {!graphView && (
                <Button
                  onClick={() => setGraphTab(3)}
                  appearance="ghost"
                  size="xs"
                  className={`${
                    graphTab === 3
                      ? "bg-green-500 text-white"
                      : "bg-white text-black"
                  } border-black border-l-2  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
                >
                  LT Trend
                </Button>
              )}
            </ButtonGroup>
          </ButtonToolbar>
        </div>
        <ResponsiveContainer
          width={"100%"}
          height={windowSize?.width / 6.5}
          className={`border-[1px] border-mainGray `}
        >
          <ComposedChart
            margin={{
              top: 0,
              right: 50,
              left: 10,
              bottom: 0,
            }}
            layout="horizontal"
            data={
              graphTab === 1
                ? lineGraphData?.demand
                : graphTab === 2
                ? lineGraphData?.inventory
                : graphTab === 4
                ? weeklyBarGraphData
                : lineGraphData?.repData
            }
          >
            <XAxis
              fontSize={10}
              stroke="#B5B5B5"
              dataKey="date"
              type="category"
            />

            {graphTab === 2 && (
              <YAxis
                fontSize={10}
                stroke="#B5B5B5"
                dataKey="onhand"
                tickFormatter={(value: any) =>
                  value && `${formatAmount(value)}`
                }
              />
            )}

            <YAxis
              fontSize={10}
              stroke="#B5B5B5"
              tickFormatter={(value: any) => value && `${formatAmount(value)}`}
            />

            <Tooltip content={($) => <CustomTooltip data={$} />} />
            <Legend verticalAlign="top" wrapperStyle={{ fontSize: "15px" }} />
            <ReferenceLine x="Page C" stroke="green" />

            {graphTab === 1 && (
              <Bar
                name="Forecast"
                dataKey="forecast"
                stroke="#62B5E5"
                fill="#62B5E5"
                barSize={30}
                strokeWidth={3}
                // dot={{ strokeWidth: 1, r: 3 }}
              />
            )}
            {graphTab === 1 && (
              <Bar
                name="Actual Demand"
                dataKey="demand"
                stroke="#9FA1A3"
                fill="#9FA1A3"
                barSize={30}
                strokeWidth={3}
                // dot={{ strokeWidth: 1, r: 3 }}
              />
            )}
            {graphTab === 1 && (
              <Line
                name="On Order"
                dataKey="purchases"
                stroke="#0097A9"
                fill="#0097A9"
                strokeWidth={3}
                dot={{ strokeWidth: 1, r: 3 }}
              />
            )}

            {/* {graphTab === 1 && (
                <Line
                  dataKey="connect"
                  stroke="#ffffff"
                  // fill="#ffffff"
                  strokeWidth={1}
                  legendType="none"
                  strokeDasharray="5 5"
                />
              )} */}

            {graphTab === 2 && (
              <Line
                name="On Hand (Qty)"
                dataKey="onhand"
                stroke="#0097a9"
                fill="#0097a9"
                strokeWidth={3}
                dot={{ strokeWidth: 1, r: 3 }}
              />
            )}
            {graphTab === 3 && (
              <Line
                name="Rep LT (Weeks)"
                dataKey="replt"
                stroke="#fff"
                fill="#fff"
                strokeWidth={3}
                dot={{ strokeWidth: 1, r: 3 }}
              />
            )}

            {graphTab === 4 && (
              <>
                <Bar
                  name="Remaining Inventory  (Qty)"
                  dataKey="onhand"
                  stackId="a"
                  barSize={30}
                  
                  fill="#bbed9b"
                />
                <Bar
                  name="Overstock (Qty)"
                  dataKey="ConstrainedOverstockQty"
                  stackId="a"
                  barSize={30}
                  fill="#f78383"
                />
              </>
            )}
          </ComposedChart>
        </ResponsiveContainer>
        {/* {graphTab === 3 && (
          <OverstockWaterFallGraph waterfallData={waterfallData} />
        )} */}
      </div>
    </>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;
  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          <>
            <p className="text-white text-0.95vw">
              {payload[0]?.payload?.date}
            </p>
            {payload?.map(($: any) => {
              return (
                <>
                  {$?.name !== "connect" && $?.name !== "On Hand Last 6M" && (
                    <p key={$?.name} className="text-white text-0.7vw">
                      {$?.name?.includes("Last")
                        ? $?.name.replace("Last 3M", "")
                        : $?.name.replace("Next 3M", "")}{" "}
                      : {formatAmount($?.value)}
                    </p>
                  )}
                  {$?.name === "On Hand Last 6M" && (
                    <p key={$?.name} className="text-white text-0.7vw">
                      {$?.name.replace("Last 6M", "")} :{" "}
                      {formatAmount($?.value)}
                    </p>
                  )}
                </>
              );
            })}
          </>
        </div>
      </>
    );
  }

  return null;
};

export default OverstockInventoryLineGraph;
