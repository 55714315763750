import React, { useState } from "react";
import {
  Bar,
  BarChart,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useSelector } from "react-redux";
import { Button, ButtonGroup, ButtonToolbar } from "rsuite";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";
import { useLocation } from "react-router-dom";

const InactiveInventoryZDBarGraph = (props: any) => {
  const {
    onbarClickRebalance,
    barGraphData,
    selectedRowData,
    plantLvlDataForOnhand,
    onbarClick,
    goBack,
    selectedCluster,
    plantLvlDataForRebalance,
    CurrentAlert,
    barGraphData1,
    showRebalance,
    RebalanceData,
    selectedGBPFilter,
    RebalanceData1,
    barGraphDataFromBreakDownClick,
  } = props;
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");
  const [graphTab, setGraphTab] = useState(1);
  const [GoBack, setGoBack] = useState(false);

  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );
  console.log(
    selectedGBPFilter,
    plantLvlDataForOnhand,
    CurrentAlert,
    selectedRowData,
    "==========",
    RebalanceData,
    RebalanceData1,
    plantLvlDataForRebalance
  );

  console.log(showRebalance, "bargraph :", barGraphData);

  return (
    <div className="w-full ">
      <div className="flex justify-between ">
        <div>
          {/* <span className="text-white font-medium text-0.95vw ">
            {graphTab == 1
              ? selectedCluster
                ? `Plantwise Breakdown of Untagged Inactive - ${selectedCluster}`
                :  `${selectedRowData.length > 0  ?`OnHand (Qty) Breakdown - ${selectedRowData[0]?.plant}` :'Inventory Details for Other Active Locations (Within the Cluster)'}`
              : selectedCluster
              ? `OnHand (Qty) and DOH Across Plants in ${selectedCluster}`
              : ` Inventory Details for Other Active Locations (Within the Cluster) `}{" "}
          </span> */}
         {!showRebalance ? <span className="text-white font-medium text-0.95vw ">
          {type === '2'  ?
          // TITLE CONDITIONS FOR AGEGING INACTIVE
            selectedRowData.length > 0 ? `Inventory Details for Other Active Locations (Within the Cluster)` : selectedCluster ? `Inventory Details for Other Active Locations - ${selectedCluster}` : selectedGBPFilter?.plant?.length > 0 ? `Inventory Details for -${selectedGBPFilter?.plant}` :selectedGBPFilter?.plant?.length > 0  ?`Inventory Details for- ${selectedRowData[0]?.plant}` : selectedCluster ? `Inventory Details for - ${selectedCluster}` : selectedGBPFilter?.clustername?.length > 0 ? `Inventory Details for -${selectedGBPFilter?.clustername}`:  "Inventory Details for Other Active Locations (Within the Cluster)" :
          
          // TITLE CONDITIONS FOR UNTAGGED INACTIVE
            selectedRowData?.length > 0 ? `Untagged Inactive (Qty) Breakdown - ${selectedRowData[0]?.plant}` :  selectedCluster ? `Plantwise Breakdown of Untagged Inactive - ${selectedCluster}` : selectedGBPFilter?.plant?.length > 0 ? `Untagged Inactive (Qty) Breakdown - ${selectedGBPFilter?.plant}` :   selectedGBPFilter?.clustername?.length > 0 ?   `Untagged Inactive (Qty) Breakdown -${selectedGBPFilter?.clustername}`: "Clusterwise Untagged Inactive (Qty) Breakdown "


           }
          </span> 
          : 
          <>
          <span className="text-white font-medium text-0.95vw ">
          {
            
          // TITLE CONDITIONS FOR AGEGING INACTIVE
            selectedRowData.length > 0 ? `Inventory Details for Other Active Locations (Within the Cluster)` : selectedCluster ? `Inventory Details for Other Active Locations - ${selectedCluster}` : selectedGBPFilter?.plant?.length > 0 ? `Inventory Details for -${selectedGBPFilter?.plant}` :selectedGBPFilter?.plant?.length > 0  ?`Inventory Details for- ${selectedRowData[0]?.plant}` : selectedCluster ? `Inventory Details for - ${selectedCluster}` : selectedGBPFilter?.clustername?.length > 0 ? `Inventory Details for -${selectedGBPFilter?.clustername}`:  "Inventory Details for Other Active Locations (Within the Cluster)" 
          
          // TITLE CONDITIONS FOR UNTAGGED INACTIVE


           }
          </span>
          </>
          }
          {GoBack && (
            <Button
              onClick={() => {
                goBack();
                setGoBack(false);
              }}
              size="xs"
              appearance="ghost"
              className={` bg-green-500 text-white
                 border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white ml-3`}
            >
              Go Back
            </Button>
          )}
        </div>
        {/* <ButtonToolbar className=" ">
          <ButtonGroup>
            <Button
              onClick={() => {
                setGraphTab(1);
              }}
              size="xs"
              appearance="ghost"
              className={`${
                graphTab === 1
                  ? "bg-green-500 text-white"
                  : "bg-white text-black"
              } border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
            >
              OnHand (Qty)
            </Button>
            <Button
              onClick={() => setGraphTab(2)}
              appearance="ghost"
              size="xs"
              className={`${
                graphTab === 2
                  ? "bg-green-500 text-white"
                  : "bg-white text-black"
              } border-black border-l-2  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
            >
              Rebalance
            </Button>
          </ButtonGroup>
        </ButtonToolbar> */}
      </div>
      <ResponsiveContainer
        width={"100%"}
        height={windowSize?.width / 6.5}
        className={`border-[1px]  border-mainGray`}
      >
        {!showRebalance  ? (
          <BarChart
            width={500}
            height={300}
            data={
              plantLvlDataForOnhand?.cluster?.length > 0
                ? plantLvlDataForOnhand?.cluster
                : barGraphData?.cluster
            } // Fallback for other values of currentAlert}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 30,
            }}
          >
            <XAxis
              fontSize={10}
              stroke="#B5B5B5"
              dataKey="cluster"
              angle={-45}
              dx={0}
              dy={15}
              minTickGap={-200}
              axisLine={false}
            />
            <YAxis
              stroke="#B5B5B5"
              fontSize={10}
              tickFormatter={(value: any) =>
                value && `${!showRebalance  ? "" : ""}${formatAmount(value)}`
              }
            />

            <Tooltip
              cursor={{ fill: "transparent" }}
              content={($) => <CustomTooltip data={$} graphTab={graphTab} />}
            />
            <Legend verticalAlign="top" />
            <Bar
              dataKey="notZD"

              onClick={($:any) => {
                onbarClick({ cluster: $?.cluster }, 2);
                setGoBack(true);
              }}
              name="Untagged OnHand (Qty)"
              barSize={60}
              stackId="a"
              fill="#4ACB4A"
            ></Bar>
            {/* <Bar
              dataKey="ZD"
              onClick={($:any) => {
                onbarClick({ plant: $?.cluster }, 2);
                setGoBack(true);
              }}
              name="ZD"
              barSize={60}
              stackId="a"
              fill="#A5D88C"
            ></Bar> */}
          </BarChart>
        ) : RebalanceData?.length > 0 || plantLvlDataForRebalance?.length > 0 ? (
          <ComposedChart
            width={500}
            height={300}
            data={
              plantLvlDataForRebalance?.length > 0
                ? plantLvlDataForRebalance
                : RebalanceData
            }
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 30,
            }}
          >
            <XAxis
              fontSize={10}
              stroke="#B5B5B5"
              dataKey="plant_code"
              angle={-45}
              dx={0}
              dy={15}
              minTickGap={-200}
              axisLine={false}
            />
            <YAxis
              stroke="#B5B5B5"
              fontSize={10}
              tickFormatter={(value: any) =>
                value && `${!showRebalance ? "" : ""}${formatAmount(value)}`
              }
            />
            <YAxis
              yAxisId="right-axis"
              stroke="#B5B5B5"
              fontSize={10}
              hide={false}
              orientation="right"
            />
            <Tooltip
              cursor={{ fill: "transparent" }}
              content={($) => <CustomTooltip data={$} showRebalance={showRebalance} graphTab={graphTab} />}
            />
            <Legend verticalAlign="top" />

            <Bar
              dataKey="onhand"
              name="OnHand (Qty)"
              onClick={($:any) => {
                onbarClick({ cluster: $?.plant_code }, 3);
                setGoBack(true);
              }}
              barSize={60}
              stackId="a"
              fill="#57A4AD"
            ></Bar>
            <Line
              strokeWidth={3}
              type="monotone"
              name="DOH"
              dataKey="doh"
              yAxisId="right-axis"
              stroke="#82ca9d"
            ></Line>
          </ComposedChart>
        ) : (<><div className="flex h-full justify-center items-center font-bold text-white">NO DATA AVAILABLE</div></>)}
      </ResponsiveContainer>
    </div>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;
  console.log(payload)
  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          <>
            <p className="text-white text-0.7vw">
             { props?.graphTab == 1
                ? payload[0]?.payload?.cluster
                : payload[0]?.payload.plant_code} {payload[0]?.name == "Other MRP" ?  `: ${formatNumber(payload[0]?.value)}` : props.showRebalance ? '' :  `: ${formatNumber(payload[0]?.value)}`}
            </p>

            {props.showRebalance === false
              ? payload?.map(($: any) => {
                console.log("awdadawd");
                
                  return (
                    <>
                      <p className="text-white text-0.7vw">
                        {$?.name !== "notZD"
                          ? `${$?.name?.toUpperCase()} : {$?.value}`
                          : "Non ZD"}{" "}
                      :
                        {$?.value}
                      </p>

                      {props?.showRebalance === false && $.name === "ZD" && (
                        <>
                          <p className="text-white text-0.7vw">
                            Other MRP Skul Count:{" "}
                            {formatNumber(
                              parseFloat($?.payload?.OtherMRPSkulCount)
                            )}
                          </p>
                          <p className="text-white text-0.7vw">
                            ZD Skul Count:{" "}
                            {formatNumber(parseFloat($?.payload?.ZDSkulCount))}
                          </p>
                        </>
                      )}
                    </>
                  );
                })
              :
              <>
              {payload[0]?.payload?.onhand && (
                <p className="text-white text-0.7vw">
                  On Hand (Qty) :{formatAmount(payload[0]?.payload?.onhand)}
                </p>
              )}
              {payload[0]?.payload?.doh && (
                <p className="text-white text-0.7vw">
                  DOH :{parseFloat(payload[0]?.payload?.doh)}
                </p>
              )}
            </>
              }
          </>
        </div>
      </>
    );
  }

  return null;
};

export default InactiveInventoryZDBarGraph;
